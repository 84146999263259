<template>
  <div>
    <ecat-lightbox-gallery :visible="visibleImages.visible" :current-index="visibleImages.currentIndex"
                           :images="visibleImages.images" @hide="hideVisibleImages"/>

    <b-modal
        size="lg"
        :scrollable="true"
        v-model="visibleModal"
        v-if="product"
        :title="$t('products.details.title')"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">
      <form>

        <div class="table-responsive py-3">
          <table class="table table-bordered">
            <tbody>

            <tr v-if="getCustomDetail('NAME', true)">
              <th>Wyświetlana nazwa produktu (globalna)</th>
              <td>{{ getCustomDetail('NAME', true).value }}</td>
            </tr>

            <tr v-if="getCustomDetail('NAME', false)">
              <th v-if="$store.getters['shop/getShop'](getCustomDetail('NAME', false).shopId)">
                Wyświetlana nazwa produktu (dla sklepu):
                {{ ($store.getters["shop/getShop"](getCustomDetail('NAME', false).shopId)).name }})
              </th>
              <td>{{ getCustomDetail('NAME', false).value }}</td>
            </tr>

            <tr>
              <th>{{ $t('products.details.table.category-path') }}</th>
              <td v-if="category && category.path">{{ category.path }}</td>
              <td v-else>-</td>
            </tr>

            <tr>
              <th>{{ $t('products.details.table.stock-amount') }}</th>
              <td>{{ product.totalWarehouseStock + product.totalDepotStock - product.amountReserved }}</td>
            </tr>

            <tr>
              <th>{{ $t('products.details.table.price-range') }}</th>
              <td>{{ priceHelper.format(product.minMargin, $store.getters['market/currentCurrency']) }} {{
                  product.minMargin !== product.maxMargin ? '- ' + priceHelper.format(product.maxMargin, $store.getters['market/currentCurrency']) : ''
                }}
              </td>
            </tr>

            <tr>
              <th>{{ $t('products.details.table.delivery-cost') }}</th>
              <td>
                <template v-if="getShippingCost()">
                  <p v-for="(shippingCostItem, index) in getShippingCost()" :key="index">{{
                      shippingCostItem.name
                    }}: {{ priceHelper.format(shippingCostItem.cost, $store.getters['market/currentCurrency']) }}</p>
                </template>
                <template v-else>
                  -
                </template>
              </td>
            </tr>

            <tr v-if="getCustomDetail('DESCRIPTION', true)">
              <th>Wyświetlany opis produktu (globalna)</th>
              <td>
                <div v-html="getCustomDetail('DESCRIPTION', true).value"/>
              </td>
            </tr>

            <tr v-if="getCustomDetail('DESCRIPTION', false)">
              <th v-if="$store.getters['shop/getShop'](getCustomDetail('DESCRIPTION', false).shopId)">
                Wyświetlany opis produktu (dla sklepu:
                {{ ($store.getters["shop/getShop"](getCustomDetail('DESCRIPTION', false).shopId)).name }})
              </th>
              <td>
                <div v-html="getCustomDetail('DESCRIPTION', false).value"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="py-3">
          <h5>{{ $t('products.details.table.pictures') }}</h5>

          <template v-if="jsonUtil.asArray(product.images).length > 0">
            <img class="img-fluid mr-2" v-for="(image, index) in jsonUtil.asArray(product.images)" :key="index"
                 :src="image"
                 alt width="120" @click="selectVisibleImages(jsonUtil.asArray(product.images), index)"/>
          </template>
        </div>

        <div class="py-3">
          <h5>{{ $t('products.details.table.variations') }}</h5>

          <div class="py-3" v-if="productVariations">
            <div v-for="(variation, index) in productVariations" :key="index">
              <p>{{ $t('products.variations.details') }}</p>
              <div class="table-responsive py-3">
                <table class="table table-bordered">
                  <tbody>
                  <tr>
                    <th>Nazwa wariacji w hurtowni</th>
                    <td>{{ variation.name }}</td>
                  </tr>

                  <tr>
                    <th>SKU</th>
                    <td>{{ variation.sku ? 'W' + variation.sku : "-" }}</td>
                  </tr>

                  <tr>
                    <th>EAN</th>
                    <td>{{
                        variation.ean && jsonUtil.asArray(variation.ean).length > 0 ? jsonUtil.asArray(variation.ean)[0] : "-"
                      }}
                    </td>
                  </tr>

                  <tr>
                    <th>{{ $t('products.variations.price') }}</th>
                    <td>{{
                        priceHelper.format(variation.priceMarginTaxIncluded, $store.getters["market/currentCurrency"])
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Atrybuty</th>
                    <td>
                      <div v-if="productAttribute(variation.id)">
                        <p v-for="(attribute, index) in productAttribute(variation.id).attributes" :key="index">
                          <b>{{ attribute.attributeGroupName }}</b>: {{ attribute.attributeName }}
                        </p>
                      </div>
                      <div v-else>
                        Brak
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Opis wariacji w hurtowni</th>
                    <td>
                      <div v-html="variation.description"></div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="text-right">
          <b-button class="ml-1" variant="danger" @click="hideModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import {jsonUtil} from "@/helpers/json-util";
import EcatLightboxGallery from "@/components/ecat-lightbox-gallery.vue";
import {priceHelper} from "@/helpers/price-helper";

export default {
  name: "product-details-modal",
  components: {
    EcatLightboxGallery
  },

  computed: {
    jsonUtil() {
      return jsonUtil
    },

    productAttribute() {
      return (variationId) => {
        return this.getAttribute(variationId);
      };
    },

    priceHelper() {
      return priceHelper
    }
  },

  data() {
    return {
      visibleModal: false,

      product: null,
      category: null,
      customDetails: [],
      productVariations: [],
      attributes: null,
      shippingCosts: null,

      visibleImages: {
        visible: false,
        images: [],
        currentIndex: 0
      }
    }
  },

  methods: {
    async openModal(product) {
      this.product = product
      await this.loadCustomProductDetails()
      await this.loadCategory()
      await this.loadVariations()
      await this.loadShippingCosts()
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.product = null
      this.category = null
      this.customDetails = []
      this.productVariations = []
      this.attributes = null
      this.shippingCosts = null
    },

    async loadCustomProductDetails() {
      try {
        const {data} = await axios.get(`/product/custom-details/by-product-id/${this.product.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.customDetails = data
      } catch (error) {
        // not found ignored
      }
    },

    async loadCategory() {
      try {
        const {data} = await axios.get(`/category/${this.product.categoryId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.category = data
      } catch (error) {
        // not found ignored
      }
    },

    getCustomDetail(type, global) {
      for (const productDetail of this.customDetails) {
        if (productDetail.type === type && global && !productDetail.shopId) {
          return productDetail
        } else if (productDetail.type === type && productDetail.shopId) {
          return productDetail
        }
      }

      return null
    },

    selectVisibleImages(images, currentIndex) {
      this.visibleImages.images = images
      this.visibleImages.currentIndex = currentIndex
      this.visibleImages.visible = true
    },

    hideVisibleImages() {
      this.visibleImages.images = []
      this.visibleImages.currentIndex = 0
      this.visibleImages.visible = false
    },

    async loadVariations() {
      let json = JSON.stringify({
        productId: this.product.id
      })

      const {data} = await axios.post(`/product/variation`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.productVariations = data
      await this.loadAttributes()
    },

    async loadAttributes() {
      const ids = this.productVariations.map(productVariation => productVariation.id);
      if (ids.length === 0) {
        return
      }

      const json = {
        productVariationIds: ids
      }

      const {data} = await axios.post(`/product/variation/attribute`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.attributes = new Map(data.map((obj) => [obj.productVariationId, obj]));
    },

    getAttribute(productVariationId) {
      if (!this.attributes) {
        return null
      }

      return this.attributes.get(productVariationId) || null
    },

    async loadShippingCosts() {
      try {
        const json = JSON.stringify({
          "productIds": [this.product.id]
        });

        const {data} = await axios.post(`/product/target-shipping-costs`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        if (data && data.data) {
          this.shippingCosts = new Map()

          data.data.forEach(item => {
            const productId = item.productId;
            const carrierData = {
              carrierId: item.carrierId,
              allegroCarrierId: item.allegroCarrierId,
              cost: item.cost,
              name: item.name
            };

            if (this.shippingCosts.has(productId)) {
              this.shippingCosts.get(productId).push(carrierData);
            } else {
              this.shippingCosts.set(productId, [carrierData]);
            }
          });
        }
      } catch (error) {
        console.log(error)
      }
    },

    getShippingCost() {
      if (!this.shippingCosts || this.shippingCosts.size === 0) {
        return null;
      }

      return this.shippingCosts.get(this.product.id) || null;
    }
  }

}
</script>